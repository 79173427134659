<template>
  <div class="special">
    <item-title :title="{name: '特色服务', en_1: 'SPECIAL', en_2: 'SERVICES'}" />
    <div v-if="listData.length > 0" class="special-content">
      <img class="bg" src="../../assets/img/customerService/special_bg.png" alt="">
      <div class="top">
        <div class="round-wrapper">
          <div :class="[listData[6] && listData[7] ? 'round-8' : listData[6] ? 'round-7' : 'round-6','round']">
            <img class="round-bg" src="../../assets/img/customerService/round.png" alt="">
            <div class="round-title">
              <div class="en">MINGYA</div>
              <div class="cn">特色服务</div>
            </div>
            <div v-if="is_mobile && listData[0]" class="item-box">
              <div v-if="listData[0]" class="round-item round-item1">
                <div @click="currentIndex = 0" :class="[currentIndex == 0 ? 'active' : '' , 'item']">
                  <span class="iconfont icon-mingya-1"></span>
                  
                </div>
                <div class="name">{{listData[0].name}}</div>
              </div>
              <div v-if="listData[1]" class="round-item round-item2">
                <div @click="currentIndex = 1" :class="[currentIndex == 1 ? 'active' : '' , 'item']">
                  <span class="iconfont icon-mingya-2"></span>
                  
                </div>
                <div class="name">{{listData[1].name}}</div>
              </div>
              <div v-if="listData[2]" class="round-item round-item3">
                <div @click="currentIndex = 2" :class="[currentIndex == 2 ? 'active' : '' , 'item']">
                  <span class="iconfont icon-mingya-3"></span>
                  
                </div>
                <div class="name">{{listData[2].name}}</div>
              </div>
              <div v-if="listData[3]" class="round-item round-item4">
                <div @click="currentIndex = 3" :class="[currentIndex == 3 ? 'active' : '' , 'item']">
                  <span class="iconfont icon-mingya-4"></span>
                  
                </div>
                <div class="name">{{listData[3].name}}</div>
              </div>
              <div v-if="listData[4]" class="round-item round-item5">
                <div @click="currentIndex = 4" :class="[currentIndex == 4 ? 'active' : '' , 'item']">
                  <span class="iconfont icon-mingya-5"></span>
                  
                </div>
                <div class="name">{{listData[4].name}}</div>
              </div>
              <div v-if="listData[5]" class="round-item round-item6">
                <div @click="currentIndex = 5" :class="[currentIndex == 5 ? 'active' : '' , 'item']">
                  <span class="iconfont icon-mingya-6"></span>
                  
                </div>
                <div class="name">{{listData[5].name}}</div>
              </div>
              <div v-if="listData[6]" class="round-item round-item6">
                <div @click="currentIndex = 6" :class="[currentIndex == 6 ? 'active' : '' , 'item']">
                  <span class="iconfont icon-mingya-7"></span>
                  
                </div>
                <div class="name">{{listData[6].name}}</div>
              </div>
              <div v-if="listData[7]" class="round-item round-item6">
                <div @click="currentIndex = 7" :class="[currentIndex == 7 ? 'active' : '' , 'item']">
                  <span class="iconfont icon-mingya-8"></span>
                  
                </div>
                <div class="name">{{listData[7].name}}</div>
              </div>
            </div>
            <template v-else-if="!is_mobile && listData[0]">
              <div v-if="listData[0]" class="round-item round-item1">
                <div @click="currentIndex = 0" :class="[currentIndex == 0 ? 'active' : '' , 'item']">
                  <span class="iconfont icon-mingya-1"></span>
                  <div class="name">{{listData[0].name}}</div>
                </div>
                <div :class="[currentIndex == 0 ? 'active' : '' , 'pointer']"></div>
              </div>
              <div v-if="listData[1]" class="round-item round-item2">
                <div @click="currentIndex = 1" :class="[currentIndex == 1 ? 'active' : '' , 'item']">
                  <span class="iconfont icon-mingya-2"></span>
                  <div class="name">{{listData[1].name}}</div>
                </div>
                <div :class="[currentIndex == 1 ? 'active' : '' , 'pointer']"></div>
              </div>
              <div v-if="listData[2]" class="round-item round-item3">
                <div @click="currentIndex = 2" :class="[currentIndex == 2 ? 'active' : '' , 'item']">
                  <span class="iconfont icon-mingya-3"></span>
                  <div class="name">{{listData[2].name}}</div>
                </div>
                <div :class="[currentIndex == 2 ? 'active' : '' , 'pointer']"></div>
              </div>
              <div v-if="listData[3]" class="round-item round-item4">
                <div @click="currentIndex = 3" :class="[currentIndex == 3 ? 'active' : '' , 'item']">
                  <span class="iconfont icon-mingya-4"></span>
                  <div class="name">{{listData[3].name}}</div>
                </div>
                <div :class="[currentIndex == 3 ? 'active' : '' , 'pointer']"></div>
              </div>
              <div v-if="listData[4]" class="round-item round-item5">
                <div @click="currentIndex = 4" :class="[currentIndex == 4 ? 'active' : '' , 'item']">
                  <span class="iconfont icon-mingya-5"></span>
                  <div class="name">{{listData[4].name}}</div>
                </div>
                <div :class="[currentIndex == 4 ? 'active' : '' , 'pointer']"></div>
              </div>
              <div v-if="listData[5]" class="round-item round-item6">
                <div @click="currentIndex = 5" :class="[currentIndex == 5 ? 'active' : '' , 'item']">
                  <span class="iconfont icon-mingya-6"></span>
                  <div class="name">{{listData[5].name}}</div>
                </div>
                <div :class="[currentIndex == 5 ? 'active' : '' , 'pointer']"></div>
              </div>
              <div v-if="listData[6]" class="round-item round-item7">
                <div @click="currentIndex = 6" :class="[currentIndex == 6 ? 'active' : '' , 'item']">
                  <span class="iconfont icon-mingya-7"></span>
                  <div class="name">{{listData[6].name}}</div>
                </div>
                <div :class="[currentIndex == 6 ? 'active' : '' , 'pointer']"></div>
              </div>
              <div v-if="listData[7]" class="round-item round-item8">
                <div @click="currentIndex = 7" :class="[currentIndex == 7 ? 'active' : '' , 'item']">
                  <span class="iconfont icon-mingya-8"></span>
                  <div class="name">{{listData[7].name}}</div>
                </div>
                <div :class="[currentIndex == 7 ? 'active' : '' , 'pointer']"></div>
              </div>
            </template>
          </div>
        </div>

      </div>
      <div class="main">
        <div class="main-content-wrapper">
          <div class="main-content">
            <div class="title">
              <div class="cn">
                <span>{{listData[currentIndex].name}}</span>
                <div class="tag">特色服务</div>
              </div>
              <div class="en">{{listData[currentIndex].name_en}}</div>
            </div>
            <div class="content-box">
              <section class="section">
                <div class="label intro"></div>
                <div class="detail">
                  <div class="icon"></div>
                  <div class="text"><pre>{{listData[currentIndex].discribe}}</pre></div>
                </div>
              </section>
              <section class="section">
                <div class="label obj"></div>
                <div class="detail">
                  <div class="icon"></div>
                  <div class="text"><pre>{{listData[currentIndex].target}}</pre></div>
                </div>
              </section>
              <section class="section">
                <div class="label con"></div>
                <div class="detail">
                  <div class="icon"></div>
                  <div class="text"><pre>{{listData[currentIndex].content}}</pre></div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemTitle from '@/components/common/itemTitle'
import { getSpecialService } from '@/api/customerService'
import { isMobile } from '@/helper/utils'
export default {
  components: {
    ItemTitle
  },
  data() {
    return {
      is_mobile: isMobile(),
      listData: [],
      currentIndex: 0
    }
  },
  created() {
    // console.log(this.isMobile)
    // this.isMobile = isMobile()
    // console.log(isMobile)
    this.getListData()
  },
  methods: {
    // 获取特色服务列表数据
    async getListData() {
      const res = await getSpecialService('')
      this.listData = res.list
      // this.listData.splice(7,1)
      // console.log(this.listData)
      this.$emit('loading', false)
    }
  }
}
</script>

<style lang="scss" scoped>
pre {
  margin-bottom: 0;
  font-family: 'Microsoft YaHei', Arial, sans-serif;
  text-align: justify;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.special-content {
  margin-top: 0.6rem;
  position: relative;
  min-height: 11rem;
  // margin-top: -6rem;
  .bg {
    width: 100%;
  }
  .top {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    .round {
      width: 9.45rem;
      position: absolute;
      left: 50%;
      top: 0.88rem;
      transform: translateX(-50%);
      .round-bg {
        width: 100%;
      }
      .round-title {
        position: absolute;
        left: 50%;
        top: 2.1rem;
        text-align: center;
        transform: translateX(-50%);
        .en {
          font-size: 0.72rem;
          color: #f4f4f4;
          letter-spacing: 0.2rem;
        }
        .cn {
          font-size: 0.36rem;
          color: #fe6917;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 0.5rem;
          letter-spacing: 0.16rem;
          width: 100%;
        }
      }
      .center {
        width: 1px;
        height: 1px;
        background-color: blue;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
      .round-item {
        // border: 1px solid red;
        // width: .8rem;
        height: 4.98rem;
        position: absolute;
        left: 50%;
        top: -0.25rem;
        margin-left: -0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .item {
          width: 1rem;
          height: 1rem;
          background: #ffffff;
          box-shadow: 0px 5px 30px 0px rgba(0, 23, 60, 0.05);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fef6f6;
          font-size: 0.9rem;
          position: relative;
          cursor: pointer;
          .iconfont {
            font-size: 0.9rem;
          }
          &.active {
            span {
              color: rgba(239, 114, 115, 0.2);
            }
            .name {
              background-color: rgba(252, 10, 10, 0.6);
              color: #ffffff;
              box-shadow: 0 0 0px 0.5rem rgba(238, 93, 94, 0.2);
              animation: shine 1s ease infinite;
            }
          }
          .name {
            color: #555555;
            font-size: 0.16rem;
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            text-align: center;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
          }
        }
        .pointer {
          width: 0.05rem;
          height: 0.4rem;
          background-color: #f1f1f2;
          margin-top: 0.4rem;
          &.active {
            background-color: rgba(252, 10, 10, 0.6);
          }
        }
        transform-origin: center bottom;
        
      }
      &.round-7 {
        .round-item {
          &.round-item1 {
            transform: rotateZ(-69deg);
            .iconfont {
              transform: rotateZ(69deg);
            }
            .name {
              transform: translateX(-50%) translateY(-50%) rotateZ(69deg);
              transform-origin: center center;
            }
          }
          &.round-item2 {
            transform: rotateZ(-46deg);
            .iconfont {
              transform: rotateZ(46deg);
            }
            .name {
              transform: translateX(-50%) translateY(-50%) rotateZ(46deg);
              transform-origin: center center;
            }
          }
          &.round-item3 {
            transform: rotateZ(-23deg);
            .iconfont {
              transform: rotateZ(23deg);
            }
            .name {
              transform: translateX(-50%) translateY(-50%) rotateZ(23deg);
              transform-origin: center center;
            }
          }
          &.round-item4 {
            transform: rotateZ(0deg);
            .iconfont {
              transform: rotateZ(0deg);
            }
            .name {
              transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
              transform-origin: center center;
            }
          }
          &.round-item5 {
            transform: rotateZ(23deg);
            .iconfont {
              transform: rotateZ(-23deg);
            }
            .name {
              transform: translateX(-50%) translateY(-50%) rotateZ(-23deg);
              transform-origin: center center;
            }
          }
          &.round-item6 {
            transform: rotateZ(46deg);
            .iconfont {
              transform: rotateZ(-46deg);
            }
            .name {
              transform: translateX(-50%) translateY(-50%) rotateZ(-46deg);
              transform-origin: center center;
            }
          }
          &.round-item7 {
            transform: rotateZ(69deg);
            .iconfont {
              transform: rotateZ(-69deg);
            }
            .name {
              transform: translateX(-50%) translateY(-50%) rotateZ(-69deg);
              transform-origin: center center;
            }
          }
        }
      }
      &.round-8 {
        .round-item {
          &.round-item1 {
            transform: rotateZ(-70deg);
            .iconfont {
              transform: rotateZ(70deg);
            }
            .name {
              transform: translateX(-50%) translateY(-50%) rotateZ(70deg);
              transform-origin: center center;
            }
          }
          &.round-item2 {
            transform: rotateZ(-50deg);
            .iconfont {
              transform: rotateZ(50deg);
            }
            .name {
              transform: translateX(-50%) translateY(-50%) rotateZ(50deg);
              transform-origin: center center;
            }
          }
          &.round-item3 {
            transform: rotateZ(-30deg);
            .iconfont {
              transform: rotateZ(30deg);
            }
            .name {
              transform: translateX(-50%) translateY(-50%) rotateZ(30deg);
              transform-origin: center center;
            }
          }
          &.round-item4 {
            transform: rotateZ(-10deg);
            .iconfont {
              transform: rotateZ(10deg);
            }
            .name {
              transform: translateX(-50%) translateY(-50%) rotateZ(10deg);
              transform-origin: center center;
            }
          }
          &.round-item5 {
            transform: rotateZ(10deg);
            .iconfont {
              transform: rotateZ(-10deg);
            }
            .name {
              transform: translateX(-50%) translateY(-50%) rotateZ(-10deg);
              transform-origin: center center;
            }
          }
          &.round-item6 {
            transform: rotateZ(30deg);
            .iconfont {
              transform: rotateZ(-30deg);
            }
            .name {
              transform: translateX(-50%) translateY(-50%) rotateZ(-30deg);
              transform-origin: center center;
            }
          }
          &.round-item7 {
            transform: rotateZ(50deg);
            .iconfont {
              transform: rotateZ(-50deg);
            }
            .name {
              transform: translateX(-50%) translateY(-50%) rotateZ(-50deg);
              transform-origin: center center;
            }
          }
          &.round-item8 {
            transform: rotateZ(70deg);
            .iconfont {
              transform: rotateZ(-70deg);
            }
            .name {
              transform: translateX(-50%) translateY(-50%) rotateZ(-70deg);
              transform-origin: center center;
            }
          }
        }
      }
      &.round-6 {
        .round-item {
          &.round-item1 {
          transform: rotateZ(-60deg);
          .iconfont {
            transform: rotateZ(60deg);
          }
          .name {
            transform: translateX(-50%) translateY(-50%) rotateZ(60deg);
            transform-origin: center center;
          }
        }
        &.round-item2 {
          transform: rotateZ(-36deg);
          .iconfont {
            transform: rotateZ(36deg);
          }
          .name {
            transform: translateX(-50%) translateY(-50%) rotateZ(36deg);
            transform-origin: center center;
          }
        }
        &.round-item3 {
          transform: rotateZ(-12deg);
          .iconfont {
            transform: rotateZ(12deg);
          }
          .name {
            transform: translateX(-50%) translateY(-50%) rotateZ(12deg);
            transform-origin: center center;
          }
        }
        &.round-item4 {
          transform: rotateZ(12deg);
          .iconfont {
            transform: rotateZ(-12deg);
          }
          .name {
            transform: translateX(-50%) translateY(-50%) rotateZ(-12deg);
            transform-origin: center center;
          }
        }
        &.round-item5 {
          transform: rotateZ(36deg);
          .iconfont {
            transform: rotateZ(-36deg);
          }
          .name {
            transform: translateX(-50%) translateY(-50%) rotateZ(-36deg);
            transform-origin: center center;
          }
        }
        &.round-item6 {
          transform: rotateZ(60deg);
          .iconfont {
            transform: rotateZ(-60deg);
          }
          .name {
            transform: translateX(-50%) translateY(-50%) rotateZ(-60deg);
            transform-origin: center center;
          }
        }
        }
      }
      
    }
  }
  @keyframes shine {
    0% {
      box-shadow: 0 0 0px 0px rgba(241, 105, 31, 1);
    }
    100% {
      box-shadow: 0 0 0px 0.15rem rgba(245, 10, 213, 0);
    }
  }
  .main {
    // display: none;
    position: relative;
    width: 10.19rem;
    min-height: 6.65rem;
    left: 50%;
    top: 0;
    margin-top: -3rem;
    transform: translateX(-50%);
    background-image: url(../../assets/img/customerService/special_content_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding: 0.4rem 0.3rem 0.3rem;
    .main-content-wrapper {
      // background-color: #ffffff;
    }
    .main-content {
      width: 100%;
      min-height: 100%;
      
      // box-shadow: 0 8px 6px -6px black;
      // border: 1px solid red;
      padding: 0.3rem 0.3rem;
      .title {
        .cn {
          font-size: 0.36rem;
          font-weight: bold;
          color: #fe6917;
          display: flex;
          align-items: center;
          .tag {
            height: 0.36rem;
            background-color: #fe6917;
            color: #ffffff;
            font-size: 0.2rem;
            text-align: center;
            line-height: 0.36rem;
            border-radius: 0.2rem 0 0.2rem 0;
            padding: 0 0.15rem;
            margin-left: 0.17rem;
          }
        }
        .en {
          font-size: 0.22rem;
          color: #fe6917;
          opacity: 0.2;
          margin-top: 0.1rem;
          letter-spacing: 0.08rem;
        }
      }
      .section {
        display: flex;
        margin-top: 0.5rem;
        .label {
          width: 1.2rem;
          height: 0.4rem;
          background-size: 100% 100%;
          &.intro {
            background-image: url(../../assets/img/customerService/intro.png);
          }
          &.obj {
            background-image: url(../../assets/img/customerService/obj.png);
          }
          &.con {
            background-image: url(../../assets/img/customerService/content.png);
          }
        }
        .detail {
          margin-left: 0.2rem;
          flex: 1;
          display: flex;
          padding-top: 0.1rem;
          .icon {
            width: 0.12rem;
            height: 0.12rem;
            border-radius: 50%;
            border: 0.04rem solid #fe6917;
            margin-top: 0.07rem;
          }
          .text {
            flex: 1;
            margin-left: 0.1rem;
            font-size: 0.16rem;
            color: #666666;
            line-height: 0.25rem;
            //display: -webkit-box;
            //-webkit-box-orient: vertical;
            //-webkit-line-clamp: 2;
            //overflow: hidden;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $moble_width) {
  
  .main-box {
    
    .content {
      .special-content {
        height: auto;
        min-height: 9rem;
        .top .round .round-title .cn {
          font-size: 0.6rem;
          font-weight: bold;
          top: 0.3rem;
        }
        .main .main-content .title .cn {
          font-size: .24rem;
          .tag {
            transform: scale(.8);
          }
        }
      }
    }
  }
  .special-content {
    margin-top: 0.2rem;
    overflow: visible;
    .top {
      .round-wrapper {
        transform: scale(0.5);
        transform-origin: center 0;
      }
      .round {
        overflow-x: scroll;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1.5rem;
        .round-bg {
          width: 150%;
        }
        .round-title {
          top: 0rem;
        }
        .item-box {
          position: absolute;
          margin-top: 1.5rem;
          left: 0;
          top: 0;
          width: 100%;
          // border: 1px solid red;
          display: flex;
          flex-wrap: wrap;
          // justify-content: space-between;
          .pointer {
            display: none;
          }
          padding: 0 .3rem;
          .round-item {
            height: 100%;
            margin-right: 1.07rem;
            margin-bottom: .2rem;
            &:nth-child(4n) {
              margin-right: 0;
            }
            .name {
              font-size: 22px;
              width: 1.4rem;
              text-align: center;
              margin-top: .2rem;
            }
            transform: rotateZ(0deg) !important;
            position: static!important;
            margin-left: 0!important;
            .item {
              
              &.active {
                background-color: #FB5C5D;
                .iconfont {
                  color: #FFE5E5;
                }
                animation: shine 1.5s ease infinite;
              }
              transform: scale(1)!important;
              width: 1.4rem!important;
              height: 1.4rem!important;
            }
            .name {
              transform: translateX(0) translateY(0) rotateZ(0)!important;
            }
            .iconfont {
              transform: rotateZ(0deg)!important;
            }
          }
        }
        // .round-item {
        //   &.round-item1 {
        //         transform: rotateZ(-30deg);
        //         top: .37rem;
        //         .name {
        //           transform: translateX(-50%) translateY(-50%) rotateZ(30deg);
        //         }
        //         .iconfont {
        //               transform: rotateZ(30deg)
        //         }
        //   }
        //   &.round-item2 {
        //         transform: rotateZ(0deg);
        //         top: 1rem;
        //         .name {
        //           transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
        //         }
        //         .iconfont {
        //               transform: rotateZ(0deg)
        //         }
        //   }
        //   &.round-item3 {
        //         transform: rotateZ(30deg);
        //         top: .37rem;
        //         .name {
        //           transform: translateX(-50%) translateY(-50%) rotateZ(-30deg);
        //         }
        //         .iconfont {
        //               transform: rotateZ(-30deg)
        //         }
        //   }
        //   &.round-item4 {
        //         transform: rotateZ(-30deg);
        //         top: 2.2rem;
        //         .name {
        //           transform: translateX(-50%) translateY(-50%) rotateZ(30deg);
        //         }
        //         .iconfont {
        //               transform: rotateZ(30deg)
        //         }
        //   }
        //   &.round-item5 {
        //         transform: rotateZ(0deg);
        //         top: 2.8rem;
        //         .name {
        //           transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
        //         }.iconfont {
        //               transform: rotateZ(0deg)
        //         }
        //   }
        //   &.round-item6 {
        //         transform: rotateZ(30deg);
        //         top: 2.2rem;
        //         .name {
        //           transform: translateX(-50%) translateY(-50%) rotateZ(-30deg);
        //         }
        //         .iconfont {
        //               transform: rotateZ(-30deg)
        //         }
        //   }
        //   .pointer {
        //       display: none!important;
        //     }
        //   .item {
        //     transform: scale(1.4);
        //     transform-origin: center 100%;
            
        //   }
        // }
      }
    }
    .main {
      position: relative;
      height: auto;
      width: 100%;
      // top: 4.35rem * 0.5;
      top: 0;
      margin-top: 0rem;
      background-size: 100% auto;
      padding: 0;
      padding-top: 0.4rem;
      .main-content-wrapper {
        background: url(../../assets/img/customerService/special_content_bg_bottom1.png)
          no-repeat center bottom;
        background-size: 98% 100%;
        padding: 0 0.3rem 0.3rem;
      }

      .main-content .title .en {
        // display: none;
        font-size: 12px;
        letter-spacing: 0.01rem;
      }
      .main-content {
        padding: .1rem .1rem .3rem;
        .section {
          display: block;
          .detail {
            margin-left: 0;
          }
        }
      }
    }
  }
}
@media screen and (device-width: 414px) {
  .special-content {
    .main {
      margin-top: -.3rem;
    }
  }  
}
@media screen and (device-width: 411px) {
  .special-content {
    .main {
      margin-top: -.3rem;
    }
  }  
}
</style>